<template>
  <div>
    <slot v-if="!error" />
    <div v-else class="error-container">
      <q-icon name="error" size="64px" color="red" />
      <h6>Something went wrong</h6>
      <p>{{ error.message }}</p>
      <!-- <p>To report an issue, <a href="#" @click.prevent="reportIssue">click here</a></p> -->
      <q-btn color="primary" rounded @click="handleRetry">
        <q-icon name="refresh" color="white" />
        Retry</q-btn>
    </div>
  </div>
</template>

<script setup>
import { ref, onErrorCaptured } from 'vue';

const error = ref(null);

const emit = defineEmits('retry')

onErrorCaptured((err) => {
  error.value = err;
  return false;
});

const handleRetry = () => {
  error.value = null;
  // location.reload();
  emit('retry')

};
</script>
<style lang="scss" scoped>
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;

  h6 {
    margin: 10px;
  }

  p {
    font-style: italic;
  }


  button {
    text-transform: capitalize
  }
}
</style>
